.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.register-banner {
  height: 400px;
  /*background-image: url('./assets/static/images/banner-home.jpg');*/
  background-color: #f29600;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.MuiButton-containedSecondary {
  color: black !important;
  background-color: #f5c300 !important;
}

.MuiButton-outlinedSecondary {
  color: rgba(9, 9, 9, 0.81) !important;
  border: 1px solid rgb(3, 3, 3) !important;
}

.makeStyles-heroContent-2 {
  margin-top: 40px !important;
}

.container {
  margin-top: 90px;
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .4rem 1rem;
  margin-bottom: 2rem;
  list-style: none;
  border-radius: 0rem;
  font-size: 0.8rem;
  font-weight: bolder;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

a {
  color: #3366FF;
}

.MuiTypography-body1 {
    color: #242424 !important;
}

.MuiButton-label {
    font-weight: 700 !important;
}

.offer {
  color: #f5c300 !important;
}

.mason-question {
  background-color: whitesmoke;
  border-radius: 8px;
  padding: 16px;
}